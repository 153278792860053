$header-background-color: $steel-blue-variant-3;

.fls-sp-detail {
    $footer-height: 0rem;
    $navmenu-height: 6rem;
    min-height: calc(100vh - ($footer-height + $navmenu-height));
    background-color: $steel-blue-variant-5;

    &__heading-section {
        height: 100%;
        width: 100%;
        min-height: 13.5rem;
        position: relative;
        background-color: $header-background-color;

        &--short {
            min-height: 13.5rem;
        }
    }

    &__heading-wrapper {
        min-width: 120rem;
        max-width: 1200px;
        padding-left: 0.5rem;
        padding: 4rem 0 5.5rem 1rem;
        margin: 0 auto;
        position: relative;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 3rem;

        @include media-query('component-max-width') {
            padding: 3.5rem 4rem 5rem 4rem;
            min-width: 100rem;
            max-width: 1000px;
        }

        h1 {
            font-size: 3.2rem;
        }

        p {
            text-align: left;
            font-weight: bold;
        }
    }

    &__part-number-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 370px;
    }

    &__headerinfo-label {
        align-self: start;
        padding-bottom: 0.8rem;
        text-decoration: underline;
        text-underline-position: under;
    }

    &__content-section {
        @include component-max-width
    }

    &__focused-info {
        min-height: 20rem;
        border-radius: 0.4rem;
        position: relative;
        z-index: 2;
        top: -4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 1;

        &-main-container {
            min-width: 120rem;
            max-width: 1200px;
            display: flex;
            gap: 1.8rem;
            padding: 0 2rem;

            @include media-query('component-max-width') {
                min-width: 100rem;
                max-width: 1000px;
            }
        }

        &-wrapper {
            width: 100%;
            min-width: 30rem;
            padding: 0.7rem;
            position: relative;
            box-shadow: $semi-prominent-box-shadow;
            flex-grow: 0;
            flex-shrink: 1;
            background-color: $white;
        }

        &-approve-btn {
            margin: 0;
        }
    }

    &__stacked-list {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        gap: 1.75rem;

        &--reversed {
            flex-direction: column-reverse;
            justify-content: flex-end;
        }
    }

    &__stacked-list-item {
        margin: 1rem 2rem 0 2rem;
        width: contain;

        &-value-text {
            line-height: 1.75rem;
        }

        &-list-text {
            line-height: 1.75rem;
            white-space: pre-wrap;
        }
    }

    &__location-select-and-tabs-wrapper {
        display: flex;
        gap: 1rem;
        border-bottom: 0.05rem solid $fls-blue-variant-3;
        margin: 4rem 0 0 0;
        position: relative;

        @include media-query('component-max-width') {
            min-width: 100rem;
            max-width: 1200px;
        }

        .fls-select {
            background-color: $steel-blue-variant-6;
            border: 1px solid $fls-blue-variant-4;
            border-radius: 0.4rem 0.4rem 0 0;
            margin: 0 0;
            border-bottom: 0;
            min-height: 4.5rem;
            justify-content: center;
            align-items: center;

            .fls-select__selected {
                justify-content: center;
            }

            &:hover {
                border: $electric-blue-border;
                border-radius: 0.4rem 0.4rem 0 0;
            }
        }

        .fls-tabs-selector {
            background-color: $steel-blue-variant-6;
            border: 1px solid $fls-blue-variant-4;
            border-radius: 0.4rem 0.4rem 0 0;
            margin: 0 0;
            border-bottom: 0;
            min-height: 4.5rem;
            justify-content: center;
            align-items: center;
            display: flex;

            &:hover {
                border: $electric-blue-border;
                border-radius: 0.4rem 0.4rem 0 0;
            }

            .fls-tabs-selector__selected {
                border-radius: 0.4rem 0.4rem 0 0;
            }
        }
    }

    &__tablist {
        bottom: -0.1rem;
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

    &__tab-button {
        position: relative;
        font-size: 1.6rem;
        text-transform: uppercase;
        letter-spacing: 0.15rem;
        color: $fls-blue;
        min-height: 4.5rem;
        background-color: $steel-blue-variant-6;
        border: none;
        cursor: pointer;
        padding: 1.3rem 3.5rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
        border-radius: 0.4rem 0.4rem 0 0;
        border-top: 1px solid $fls-blue-variant-4;
        border-left: 1px solid $fls-blue-variant-4;
        border-right: 1px solid $fls-blue-variant-4;

        &:hover {
            color: $electric-blue;

            &::before {
                left: 0;
                top: 0;
                border-top: $electric-blue-border;
                border-left: $electric-blue-border;
                border-right: $electric-blue-border;
                content: " ";
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                border-radius: 0.4rem 0.4rem 0 0;
            }

            &::before {
                left: 0;
                top: -1px;
                content: " ";
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                border-bottom: $electric-blue-border;
            }
        }

        &--active {
            background-color: $white;
            border-bottom: 0.25rem solid $electric-blue;
            color: $electric-blue;

            &:hover {

                &::before {
                    left: 0;
                    top: 0;
                    content: " ";
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    border-top: $electric-blue-border;
                    border-left: $electric-blue-border;
                    border-right: $electric-blue-border;
                    border-bottom: .25rem solid $electric-blue;
                }
            }
        }
    }

    &__data-health {
        min-width: 16rem;
        background-color: $steel-blue-variant-5;
        padding: 2rem;
        margin-left: auto;
        overflow: auto;
        box-shadow: $semi-prominent-box-shadow;
    }

    &__data-health-list {
        padding-top: .5rem;
        display: flex;
        flex-direction: column;
    }

    &__table-error-msg {
        color: $dark-grey;
        opacity: 0.5;
        margin: 2rem 0;
        text-align: center;
    }

    &__table-info-msg {
        color: $dark-grey;
        opacity: 0.7;
        margin: 2rem 0;
        font-size: 22px;
    }

    &__iframe-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 1.5rem;
        margin-bottom: 5rem;

        &-note {
            color: $electric-blue;
        }

        iframe {
            width: 100%;
            height: 100vh;
            border: $electric-blue-border;
        }
    }

    .restricted-data-message-container {
        display: flex;
        justify-content: center;

        .restricted-data-message-box {
            display: flex;
            align-items: center;
            border: $electric-blue-border;
            margin: 1rem;
            border-width: 0.25rem;
            padding: 1.5rem;
            padding-top: 1rem;
            padding-bottom: 1rem;

            .restricted-data-exclamation {
                color: $electric-blue;
                padding-right: 1.5rem;
            }

            .restricted-data-message {
            }
        }
    }
}
